<template>
  <div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="4">
        <DatePicker v-model="date" type="daterange" size="small" transfer clearable :options="dateOptions"
          placement="bottom-end" placeholder="选择日期" style="width:100%" @on-change="onChangeDate"></DatePicker>
      </i-col>
      <i-col span="4">
        <i-select size="small" placeholder="选择项目名称" clearable v-model="query.projectName">
          <i-option :value="item" v-for="item in projectArray" :key="item">{{ item }}</i-option>
        </i-select>
      </i-col>
      <i-col span="4">
        <i-select size="small" placeholder="选择事件名称" clearable v-model="query.eventCode">
          <i-option :value="item.eventCode" v-for="item in eventArray" :key="item.eventCode">{{ item.eventName
            }}</i-option>
        </i-select>
      </i-col>
      <i-col span="6" class="m-b-5">
        <i-input size="small" placeholder="关键字：支持项目名称和事件名称查询" v-model="query.keyword"></i-input>
      </i-col>
      <i-col span="4" class="m-b-5">
        <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch()">搜索</i-button>
      </i-col>
    </Row>
    <div>
      <Table stripe max-height="620" size="small" :data="tableList" :columns="tableColumns" :loading="tableLoading">
      </Table>
      <div class="paging_style">
        <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator show-sizer
          :page-size-opts="pagesizeSetting" @on-page-size-change='onChangePageSize' :current="query.pageNumber"
          @on-change="onChangePageNum"></Page>
      </div>
    </div>
  </div>
</template>

<script>
import { ParseDate } from '@/utils/dateFormat'
import { getEventList, getProjectNameList, getRecordPage } from '@/api/event/record'
export default {
  data () {
    return {
      pagesizeSetting: [15, 50, 100],
      dateOptions: {
        shortcuts: [
          {
            text: '近一周',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              return [start, end]
            }
          },
          {
            text: '近一个月',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              return [start, end]
            }
          },
          {
            text: '近三个月',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              return [start, end]
            }
          }
        ]
      },
      date: [],
      query: {
        pageNumber: 1,
        pageSize: 15,
        endDate: '',
        eventCode: '',
        keyword: '',
        projectName: '',
        startDate: ''
      },
      eventArray: [],
      projectArray: [],
      tableLoading: false,
      tableList: [],
      total: 0,
      tableColumns: [
        { title: '访问时间', align: 'center', key: 'visitTime' },
        {
          title: '访问人员',
          align: 'center',
          key: 'visitUserName',
          render: (h, { row }) => {
            return h('span', `${row.visitUserName} - ${row.visitUserCompanyName}`)
          }
        },
        { title: '项目名称', align: 'center', key: 'project' },
        { title: '事件名称', align: 'center', key: 'eventName' },
        {
          title: '详细内容',
          align: 'center',
          render: (h, { row }) => {
            return h('div', row.properties.toString())
          }
        }

      ]
    }
  },
  created () {
    this.getEventData()
    this.getProjectNameData()
    this.getTableData()
  },
  methods: {
    /**
     * 初始化日期
     */
    initDates () {
      // 默认日期为近一周
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      this.date = [ParseDate(start), ParseDate(end)]
      this.query.startDate = this.date[0]
      this.query.endDate = this.date[1]
    },
    onChangeDate (date) {
      this.query.startDate = date[0]
      this.query.endDate = date[1]
    },
    async getEventData () {
      const reuslt = await getEventList()
      if (reuslt && !reuslt.errcode) {
        this.eventArray = reuslt
      }
    },
    async getProjectNameData () {
      const reuslt = await getProjectNameList()
      if (reuslt && !reuslt.errcode) {
        this.projectArray = reuslt
      }
    },
    async getTableData () {
      this.tableLoading = true
      const reuslt = await getRecordPage(this.query).finally(() => { this.tableLoading = false })
      if (reuslt && !reuslt.errcode) {
        this.tableList = reuslt.list
        this.total = reuslt.totalRow
      } else {
        this.tableList = []
        this.total = 0
      }
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.getTableData()
    },
    onChangePageNum (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    onChangePageSize (curPageSize) {
      this.query.pageSize = curPageSize
      this.query.pageNumber = 1
      this.getTableData()
    }
  }
}
</script>
