import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 查询事件名称
 * @param {Object} data
 * @returns
 */
export function getEventList () {
  return request.post('/ooh-event/v1/record/getEventList', {
    headers: {
      'content-type': 'application/json'
    }
  })
}

/**
 * 查询项目名称
 * @param {Object} data
 * @returns
 */
export function getProjectNameList () {
  return request.post('/ooh-event/v1/record/getProjectNameList', {
    headers: {
      'content-type': 'application/json'
    }
  })
}

/**
 * 分页获取浏览日志
 * @param {Object} data
 * @returns
 */
export function getRecordPage (data) {
  return request.post('/ooh-event/v1/record/getRecordPage', qs.stringify(data))
}
